.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: heartbeat 5s infinite;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-screenshots {
  flex-direction: row;
  justify-content: space-around;
  align-self: stretch;
}

.app-screenshot {
  margin: 10px;
  height: 500px;
}

@keyframes heartbeat
{
  0%
  {
    transform: scale( .85 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .85 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .85 );
  }
  100%
  {
    transform: scale( .85 );
  }
}